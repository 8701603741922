
export default {
  name: 'MPProductsLeftovers',
  props: {
    qtyThreshold: {
      type: Number,
      default: 0,
    },
    qtyProducts: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    showLeftoversLabel() {
      return this.qtyThreshold > this.qtyProducts
    },
  },
}
